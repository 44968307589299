.my-heading-star {
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .md\:w-64 {
    width: 16rem;
  }

  .md\:w-4 {
    width: 4rem;
  }
  .md\:w-6 {
    width: 6rem;
  }
  .md\:w-8 {
    width: 8rem;
  }
}

.w-full {
  width: 100%;
}

.mr-2 {
  margin-right: .5rem;
}

.rounded-full {
  border-radius: 9999px;
}

.bg-purple {
  --bg-opacity: 1;
  background-color: #efecff;
  background-color: rgba(239, 236, 255, var(--bg-opacity));
}

.text-white {
  --text-opacity: 1;
  color: #fff;
  color: rgba(255, 255, 255, var(--text-opacity));
}

.text-center {
  text-align: center;
}

.text-xs {
  font-size: .75rem;
}

.h-2 {
  height: .5rem;
}

.rounded-full {
  border-radius: 9999px;
}

.bg-purple-500 {
  --bg-opacity: 1;
  background-color: #8326fa;
  background-color: rgba(29, 39, 250, var(--bg-opacity));
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mb-1 {
  margin-bottom: 0.25rem;
}

.btn-continue {
  background-color: #5840fe;
  color: #fff;
}

ul li {
  font-size: 12px;
}

.star-icon {
  font-size: 20px!important;
  width: 24px;
}

.main-step {
  padding: 0 20px 0 20px;
}


.my-custom-grid-item {
padding-left: 0!important;
padding-top: 0!important;
}
.box-two {
  padding: 0 15px;
}
