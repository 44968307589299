*,
*::before,
*::after {
  border: 0;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

// color variables
$main-white-color: #ffffff;
$main-dark-blue-color: #1e2022;
$main-red-color: #364a63;
$main-yellow-color: #ffb100;
$main-light-blue-color: #485468;
$main-dark-white-color-02: #6576ff;
$main-dark-white-color-08: #597ba5;
$minor-dark-blue-color-02: #7f8dff;
$minor-dark-blue-color-08: rgba(0, 13, 37, 0.8);

:root {
  --main-bg-color: #{$main-white-color};
  --main-content-text-color: #{$main-dark-blue-color};
  --main-title-color: #{$main-red-color};
  --main-box-shadow-color: #{$main-light-blue-color};
  --main-hover-color: #{$main-dark-white-color-02};
  --main-active-color: #{$main-dark-white-color-08};
}

[data-theme="dark"] {
  --main-bg-color: #{$main-dark-blue-color};
  --main-content-text-color: #{$main-white-color};
  --main-title-color: #{$main-red-color};
  --main-box-shadow-color: #{$main-light-blue-color};
  --main-hover-color: #{$minor-dark-blue-color-02};
  --main-active-color: #{$minor-dark-blue-color-08};
}

.main-border {
  border-width: 1px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.125);
  border-radius: 4px;
}

.user-profile-hover {
  border: 1px solid transparent;
  transition: all 0.3s ease, filter 0.2s ease;
  &:hover {
    border: 1px solid var(--main-hover-color);
    color: var(--main-hover-color);
    border-radius: 9999px;
  }
  &:active {
    border: 1px solid var(--main-hover-color);
    color: var(--main-hover-color);
    border-radius: 9999px;
  }
}

@media screen and (max-width: 540px) {
  .my-container {
      margin: auto;
  }
}

@media screen and (min-width: 1025px) {
  .my-container {
      margin-left: 8rem;
      margin-right: 8rem;
  }
}
// end
