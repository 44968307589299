.land-3-agen-right ul li p,
[class~=test-box] p,
.test-box h6,
[class~=land-3-dwn-right] p {
    color: #495e71;
}

.land-3-dwn-right a {
    margin-right: .9375pc;
}

/* [class~=land-com-form] form ul li {
    margin-bottom: .9375pc;
} */

[class~=buy-now-btn] {
    border-bottom-style: solid;
}

[class~=test-box]:hover {
    background: #f2f4f7;
}

[class~=land-3-dwn-right] a:hover {
    border-left-color: #cb2f2e;
}

/* [class~=land-com-form] form ul li {
    position: relative;
}

.land-com-form form ul li:nth-child(4):before,
[class~=land-com-form] form ul li:nth-child(5):before {
    display: none;
} */

[class~=land-3-cus-tit] h2 {
    line-height: 46.5pt;
}

[class~=land-3-cus-tit] p {
    margin-top: .25pc;
}

[class~=buy-now-btn] {
    border-left-color: #b32120;
}

[class~=buy-now-btn] {
    border-bottom-color: #b32120;
}

[class~=buy-now-btn] {
    border-right-color: #b32120;
}

[class~=land-3-cus-tit] p {
    letter-spacing: .25pc;
}

[class~=test-box] h4 {}

/* [class~=land-com-form] form ul li {
    list-style-type: none;
}

[class~=land-com-form] form ul li input[type="submit"] {
    background: #3b4db1;
} */

[class~=land-3-dwn-right] a:hover {
    border-bottom-color: #bf2928;
}

[class~=land-3-dwn-right] a:hover {
    border-right-color: #cb2f2e;
}

[class~=buy-now-btn] {
    border-image: none;
}

[class~=buy-now-btn] {
    text-transform: capitalize;
}

[class~=buy-now-btn] {
    -webkit-transition: all .5s ease;
}


[class~=buy-now-btn] {
    -moz-transition: all .5s ease;
}

.test-box h6 {
    letter-spacing: .010416667in;
}

[class~=buy-now-btn] {
    -o-transition: all .5s ease;
}

[class~=buy-now-btn] {
    transition: all .5s ease;
}

.test-box h6 {
    font-size: 13px;
}

[class~=dwn-now-btn],
[class~=land-com-form] form ul li input[type="submit"] {
    height: 50px;
}

.test-box h6 {
    font-weight: 300;
}

[class~=land-3-banner-conte] ul li {
    padding-left: .3125in;
}

[class~=dwn-now-btn] {
    color: #1a2a38;
}

[class~=dwn-now-btn] {
    margin-left: auto;
}

.land-3-agen-right ul li p,
[class~=test-box] p {
    line-height: .270833333in;
}

[class~=dwn-now-btn] {
    margin-bottom: 0;
}

[class~=dwn-now-btn] {
    margin-right: auto;
}

[class~=land-3-dwn-right] a:hover {
    border-image: none;
}

[class~=dwn-now-btn] {
    margin-top: .104166667in;
}

[class~=dwn-now-btn] {
    display: inline-block;
}

/* .land-com-form form ul li input {
    border-left-width: 0;
} */

[class~=test-box] p {
    font-size: 14px;
}

[class~=test-box] img {
    width: 80px;
}

/* [class~=land-com-form] form ul li input[type="submit"] {
    background: -webkit-linear-gradient(to top, #3547ab, #3f51b5);
} */

/* [class~=dwn-now-btn],
.land-com-form form ul li input {
    border-bottom-width: .010416667in;
} */

[class~=dwn-now-btn] {
    border-radius: 3px;
}

/* [class~=dwn-now-btn],
[class~=land-com-form] form ul li input[type="submit"] {
    line-height: 37.5pt;
}

.land-com-form form ul li input {
    border-right-width: 0;
} */

[class~=test-box] img {
    height: 60pt;
}

[class~=land-3-dwn-right] a:hover,
/* [class~=land-com-form] form ul li input[type="submit"]  */
{
    color: #fff;
}

[class~=test-box] img {
    -webkit-object-fit: cover;
}

/* .land-com-form form ul li input {
    border-top-width: 0;
} */

[class~=dwn-now-btn] {
    font-weight: 700;
}

[class~=dwn-now-btn] {
    font-size: 15pt;
}

/* [class~=land-com-form] form ul li input[type="submit"] {
    background: linear-gradient(to top, #3547ab, #3f51b5);
} */

[class~=dwn-now-btn] {
    padding-left: .3125in;
}

[class~=land-3-dwn-right] a:hover {
    background: #d63938;
}

[class~=test-box] img {
    -moz-object-fit: cover;
}

[class~=dwn-now-btn] {
    padding-bottom: 0pc;
}

[class~=dwn-now-btn] {
    padding-right: .3125in;
}

[class~=land-3-dwn-right] a:hover {
    background: -webkit-linear-gradient(to top, #c72c2b, #ef4b4a);
}

[class~=test-box] img {
    -o-object-fit: cover;
}

[class~=land-3-dwn-right] a:hover {
    background: linear-gradient(to top, #c72c2b, #ef4b4a);
}

[class~=test-box] img {
    -ms-object-fit: cover;
}

[class~=dwn-now-btn] {
    padding-top: 0pc;
}

[class~=dwn-now-btn] {
    background: -webkit-linear-gradient(to right, #363795, #005c97);
}

[class~=test-box] img,
[class~=dwn-now-btn] {
    border-left-width: .010416667in;
}

.land-3-agen-right ul li p {
    margin-top: 0px;
}

.land-3-agen-right ul li p {
    margin-bottom: 1.5625pc;
}

[class~=test-box] img {
    object-fit: cover;
}

[class~=test-box] img,
[class~=dwn-now-btn] {
    border-right-width: .010416667in;
}

[class~=test-box] img,
[class~=dwn-now-btn] {
    border-top-width: .010416667in;
}

[class~=test-box] img,
[class~=dwn-now-btn] {
    border-left-style: solid;
}

[class~=dwn-now-btn],
[class~=test-box] img
/* .land-com-form form ul li input  */
{
    border-bottom-style: solid;
}

/* [class~=land-com-form] form ul li input[type="submit"] {
    text-transform: uppercase;
} */

[class~=dwn-now-btn],
[class~=test-box] img {
    border-right-style: solid;
}

[class~=test-box] img,
[class~=dwn-now-btn] {
    border-top-style: solid;
}

[class~=test-box] img,
[class~=dwn-now-btn] {
    border-left-color: #1a2a38;
}

[class~=land-3-agen-right] ul li:before {
    content: "";
}

[class~=land-3-agen-right] ul li:before {
    position: absolute;
}

[class~=land-3-agen-right] ul li:before {
    width: 25px;
}

/* .land-com-form form ul li input {
    border-left-style: none;
} */

[class~=dwn-now-btn],
[class~=test-box] img {
    border-bottom-color: #1a2a38;
}

[class~=test-box] img {
    border-radius: 50%;
}

[class~=land-3-agen-right] ul li:before {
    height: 25px;
}

[class~=test-box] img,
[class~=dwn-now-btn] {
    border-right-color: #1a2a38;
}

/* [class~=land-com-form] form ul li input[type="submit"] {
    font-weight: 600;
} */

[class~=test-box] img,
[class~=dwn-now-btn] {
    border-top-color: #1a2a38;
}

[class~=test-box] img {
    margin-top: -65px;
}

[class~=test-box] img {
    background: #fff;
}

[class~=land-3-agen-right] ul li:before {
    background: #3a4cb0;
}

[class~=test-box] img {
    border-bottom-width: 1px;
}

[class~=test-box] img,
[class~=dwn-now-btn] {
    border-image: none;
}

[class~=dwn-now-btn] {
    box-sizing: border-box;
}

[class~=land-3-agen-right] ul li:before {
    margin-left: -4.9375pc;
}

[class~=land-3-banner-conte] ul li {
    margin-bottom: 15px;
}

.land-3-cus-tit,
[class~=dwn-now-btn],
.test-box
/* [class~=land-com-form] form ul li input[type="submit"]  */
{
    text-align: center;
}

[class~=dwn-now-btn] {
    text-transform: capitalize;
}

[class~=land-3-agen-right] ul li:before {
    z-index: 2;
}

[class~=dwn-now-btn],
[class~=land-3-dwn-right] a:hover {
    -webkit-transition: all .5s ease;
}

[class~=land-3-dwn-right] a:hover,
[class~=dwn-now-btn] {
    -moz-transition: all .5s ease;
}

[class~=dwn-now-btn],
[class~=test-box]:hover[class~=test-box] img {
    transition: all .5s ease;
}

[class~=land-3-agen-right] ul li:before {
    border-radius: 3.125pc;
}

/* .land-com-form form ul li input {
    border-right-style: none;
} */

[class~=land-3-agen-right] ul li:before {
    border-left-width: .052083333in;
}

[class~=dwn-now-btn] {
    -o-transition: all .5s ease;
}

/* .land-com-form form ul li input {
    border-top-style: none;
} */

/* [class~=land-com-form] form ul,
.land-3-cus-full {} */

/* .land-com-form form ul li input {
    border-left-color: currentColor;
} */

[class~=land-3-agen-right] ul li:before {
    border-bottom-width: .052083333in;
}

/* [class~=land-com-form] form ul li input[type="submit"] {
    font-size: 16px;
} */

[class~=land-3-cus-box],
.land-3-cus-tit {
    margin-bottom: 70px;
}

[class~=land-3-cus-box] {
    float: left;
}

[class~=land-3-cus-box] {
    width: 50%;
}

[class~=land-3-cus-box] {
    padding-left: 15pt;
}

[class~=land-3-agen-right] ul li:before {
    border-right-width: .052083333in;
}

[class~=land-3-cus-box] {
    padding-bottom: 0in;
}

[class~=land-3-cus-box] {
    padding-right: 15pt;
}

[class~=land-3-cus-box] {
    padding-top: 0in;
}

[class~=test-box] img {
    padding-left: .625pc;
}

[class~=test-box] img {
    padding-bottom: .625pc;
}

.test-box {
    background: #fff;
}

.test-box {
    box-shadow: 0pc 3.75pt 1.25pc -.5pc rgba(19, 18, 18, .5);
}

.test-box {
    box-shadow: 0 3px 2.1875pc .00625in rgba(40, 30, 20, .1);
}

.test-box {
    padding-left: 22.5pt;
}

.test-box {
    padding-bottom: 22.5pt;
}

.test-box {
    padding-right: 22.5pt;
}

[class~=test-box] img {
    padding-right: .625pc;
}

.test-box {
    padding-top: 22.5pt;
}

.test-box {
    -webkit-box-shadow: 0 .1875pc .364583333in .0375pc rgba(40, 30, 20, .1);
}

[class~=land-1-foot-text] p a {
    color: #c8dae6;
}

[class~=test-box] img,
[class~=land-1-foot-full] {
    padding-top: .625pc;
}

[class~=test-box] img {
    box-sizing: border-box;
}

.test-box,
[class~=test-box] img {
    -webkit-transition: all .5s ease;
}

[class~=land-3-agen-right] ul li {
    padding-left: 5pc;
}

/* .land-com-form form ul li input {
    border-bottom-color: #d4d4d4;
} */

.test-box {
    -moz-box-shadow: 0 2.25pt 26.25pt .6px rgba(40, 30, 20, .1);
}

[class~=test-box] img,
.test-box {
    -moz-transition: all .5s ease;
}

/* .land-com-form form ul li input {
    border-right-color: currentColor;
} */

.test-box {
    border-radius: .052083333in;
}

[class~=land-3-agen-right] ul li:before {
    border-top-width: .052083333in;
}

.test-box,
[class~=land-3-dwn-right] a:hover,
[class~=test-box] img {
    -o-transition: all .5s ease;
}

[class~=land-3-agen-right] ul li:before {
    border-left-style: solid;
}

.test-box,
[class~=land-3-dwn-right] a:hover,
[class~=test-box] img {
    transition: all .5s ease;
}

[class~=land-1-foot-full] {
    background: #1a2a38;
}

[class~=land-3-agen-right] ul li:before {
    border-bottom-style: solid;
}

[class~=land-1-foot-full] {
    padding-left: 0in;
}

[class~=land-1-foot-full] {
    padding-bottom: 10px;
}

[class~=land-1-foot-full] {
    padding-right: 0in;
}

.land-event-date-left span:nth-child(1),
[class~=land-1-foot-text] p {
    text-align: center;
}

[class~=land-1-foot-text] p {
    margin-left: 0pc;
}

[class~=land-1-foot-text] p {
    margin-bottom: 0pc;
}

[class~=land-1-foot-text] p {
    margin-right: 0pc;
}

[class~=land-4-agen-full],
[class~=land-1-foot-text] {}

[class~=land-1-foot-text] p {
    margin-top: 0pc;
}

[class~=land-event-date],
.land-3-agen {
    position: relative;
}

[class~=land-1-foot-text] p {
    font-size: 14px;
}

[class~=land-event-date],
.land-3-agen {
    display: inline-block;
}

[class~=land-3-agen-right] ul li:before {
    border-right-style: solid;
}

[class~=land-event-date] {
    width: 100%;
}

.land-3-agen,
[class~=land-3-agen-right] ul li:before {
    /* border-top-style: solid; */
}

.land-event-date-left span:nth-child(1) {
    color: #000;
}

[class~=land-1-foot-text] p {
    color: #687179;
}

[class~=land-3-agen-right] ul li:before {
    border-left-color: #fff;
}

.land-event-date-left,
[class~=land-event-date-right],
[class~=land-3-agen-left],
[class~=land-3-agen-right] {
    float: left;
}

[class~=land-event-date-left] span {
    display: block;
}

.land-event-date-left {
    width: .729166667in;
}

[class~=land-event-date-right],
.land-event-date-left {
    height: 52.5pt;
}

.land-event-date-right span,
[class~=land-event-date-right] h4 {
    text-transform: capitalize;
}

.land-event-date-left {
    background: #fff;
}

.land-event-date-left {
    border-radius: 2px;
}

[class~=land-event-date-right] h4 {
    padding-top: 0pt;
}

[class~=land-event-date-right] {
    width: 300px;
}

[class~=land-3-agen-right] ul li:before {
    border-bottom-color: #fff;
}

/* [class~=land-com-form] form ul li input[type="submit"] {
    border-radius: .1875pc;
} */

[class~=land-event-date-right] h4 {
    font-size: .25in;
}

[class~=land-event-date-right] h4 {
    font-weight: 500;
}

[class~=land-event-date-right] {
    padding-left: 20px;
}

[class~=land-3-agen-right] ul::after {
    content: "";
}

.land-event-date-right span {
    letter-spacing: 2px;
}

.land-3-agen {
    width: 100%;
}

.land-event-date-left span:nth-child(1) {
    font-size: 2pc;
}

/* [class~=land-com-form] form ul li input[type="submit"] {
    cursor: pointer;
} */

.land-3-agen {
    border-image: none;
}

[class~=land-3-agen-right] ul li:before {
    border-right-color: #fff;
}

.land-3-agen {
    padding-top: 75px;
}

/* .land-com-form form ul li input {
    border-top-color: currentColor;
} */

.land-event-date-right span {
    font-weight: 300;
}

[class~=land-3-agen-left] h2 {
    font-size: .5in;
}

[class~=land-3-agen-right] ul li:before {
    border-top-color: #fff;
}

[class~=alert] {
    border-bottom-width: .75pt;
}

[class~=alert] {
    border-bottom-style: solid;
}

[class~=land-3-agen-left] h2 {
    line-height: 40.5pt;
}

[class~=land-3-agen-left] p {
    line-height: .270833333in;
}

[class~=land-3-agen-right] ul li:before
/* .land-com-form form ul li input */
{
    border-image: none;
}

[class~=land-3-agen-left] p {
    color: #495e71;
}

[class~=land-3-agen-right] ul li:before {
    box-sizing: border-box;
}

.land-3-agen {
    padding-bottom: 1.5625pc;
}

.land-3-agen {
    margin-bottom: .364583333in;
}

/* [class~=land-com-form] form ul li p a {
    color: #0b66a5;
} */

[class~=land-3-agen-right] ul {}

[class~=land-3-agen-right] iframe {
    border-left-width: 0pc;
}

[class~=land-3-agen-right] ul::after {
    background: #ececec;
}

.land-event-date-left span:nth-child(1) {
    font-weight: 600;
}

[class~=land-3-agen-left] {
    width: 30%;
}

[class~=land-3-agen-right] iframe {
    border-bottom-width: 0pc;
}

[class~=land-3-agen-right] ul li:before {
    box-shadow: 0 0pc .625pc .00625in rgba(40, 30, 20, .08);
}

[class~=land-3-agen-right] {
    width: 70%;
}

.land-event-date-left span:nth-child(1) {
    line-height: 2.8125pc;
}

[class~=land-3-agen-right] {
    padding-left: 4.375pc;
}

[class~=alert],
[class~=land-3-agen-right] {
    position: relative;
}

[class~=land-3-agen-right] iframe {
    border-right-width: 0pc;
}

[class~=alert] {
    padding-left: 1.25rem;
}

[class~=land-3-agen-right] iframe {
    border-top-width: 0pc;
}

[class~=land-3-agen-right] ul::after {
    position: absolute;
}

[class~=alert] {
    padding-bottom: .75rem;
}

[class~=land-3-agen-right] iframe {
    border-left-style: none;
}

[class~=land-3-agen-right] iframe {
    border-bottom-style: none;
}

[class~=alert] {
    padding-right: 1.25rem;
}

[class~=alert] {
    padding-top: .75rem;
}

[class~=alert] {
    margin-bottom: 1rem;
}

/* [class~=land-com-form] form ul li input[type="submit"] {
    padding-left: 0;
} */

[class~=alert] {
    border-left-width: .010416667in;
}

[class~=land-3-agen-right] iframe {
    border-right-style: none;
}

[class~=alert] {
    border-right-width: .010416667in;
}

[class~=land-3-agen-right] iframe {
    border-top-style: none;
}

[class~=alert] {
    border-top-width: .010416667in;
}

[class~=land-3-agen-right] ul::after {
    width: .010416667in;
}

[class~=land-3-agen-right] iframe {
    border-left-color: currentColor;
}

/* .land-com-form form ul li input {
    line-height: .25in;
} */

[class~=alert] {
    border-left-style: solid;
}

[class~=land-3-agen-right] iframe {
    border-bottom-color: currentColor;
}

[class~=land-3-agen-right] ul::after {
    top: 0;
}

[class~=alert] {
    border-right-style: solid;
}

[class~=alert] {
    border-top-style: solid;
}

[class~=alert] {
    border-left-color: transparent;
}

[class~=land-3-agen-right] iframe {
    border-right-color: currentColor;
}

[class~=alert] {
    border-bottom-color: transparent;
}

[class~=alert] {
    border-right-color: transparent;
}

[class~=land-3-agen-right] iframe {
    border-top-color: currentColor;
}

[class~=alert] {
    border-top-color: transparent;
}

[class~=land-3-agen-right] ul::after {
    bottom: 0;
}

[class~=alert],
[class~=land-3-agen-right] iframe {
    border-image: none;
}

/* [class~=land-com-form] form ul li input[type="submit"] {
    border-bottom-width: .0625pc;
} */

[class~=land-3-agen-right] ul::after {
    height: 97%;
}

[class~=alert] {
    border-radius: .25rem;
}

[class~=alert-success] {
    color: #155724;
}

/* .land-com-form form ul li input {
    font-size: 14px;
} */

[class~=alert-success] {
    background-color: #d4edda;
}

[class~=alert-success] {
    border-left-color: #c3e6cb;
}

[class~=alert-success] {
    border-bottom-color: #c3e6cb;
}

[class~=alert-success] {
    border-right-color: #c3e6cb;
}

[class~=alert-success] {
    border-top-color: #c3e6cb;
}

[class~=alert-success],
[class~=land-event-date-left] span:nth-child(2) {
    text-align: center;
}

/* [class~=land-com-form] form ul li input[type="submit"] {
    border-bottom-style: solid;
} */

[class~=land-3-agen-right] iframe {
    width: 100%;
}

[class~=land-3-agen-right] iframe {
    height: 142.5pt;
}

[class~=land-3-agen-right] ul li:nth-child(odd):before {
    background: #76cef1;
}

[class~=land-3-agen-right] iframe {
    pointer-events: none;
}

/* [class~=land-com-form] form ul li input[type="submit"] {
    border-bottom-color: #202f7f;
} */

[class~=land-event-date-left] span:nth-child(2) {
    color: #000;
}

/* .land-com-form form ul li input {
    outline: none;
}

.land-com-form form ul li input {
    height: 30pt;
} */

[class~=test-box]:hover {
    box-shadow: 0px 3.75pt 20px -.083333333in rgba(19, 18, 18, .5);
}

[class~=land-event-date-left] span:nth-child(2) {
    font-size: .177083333in;
}

/* [class~=land-com-form] form ul li input[type="submit"] {
    border-image: none;
} */

[class~=land-event-date-left] span:nth-child(2) {
    font-weight: 600;
}

[class~=land-event-date-left] span:nth-child(2) {
    background: #ffeb3b;
}

[class~=land-event-date-left] span:nth-child(2) {
    text-transform: uppercase;
}

/* .land-com-form form ul li input {
    background: #fff;
} */

/* .land-com-form form ul li input {
    padding-left: 40px;
} */

[class~=land-3-agen-right] ul::after {
    left: 5.125pc;
}

.land-3-agen-right ul li:nth-child(even):before {
    background: #6ae697;
}

/* .land-com-form form ul li input {
    box-sizing: border-box;
} */

[class~=land-event-date-left] span:nth-child(2) {
    margin-top: .0625pc;
}

/* [class~=land-com-form] form ul li p {
    margin-bottom: 0pc;
}

[class~=land-com-form] form ul li p {
    color: #495e71;
}

[class~=land-com-form] form ul li p {
    font-size: 13px;
} */

[class~=land-event-date-left] span:nth-child(2) {
    border-radius: 0px 0px 2px 1.5pt;
}

.land-3-agen-right iframe.clicked {
    pointer-events: auto;
}

[class~=test-box]:hover {
    -webkit-transition: all .5s ease;
}

[class~=test-box]:hover {
    -moz-transition: all .5s ease;
}

[class~=test-box]:hover {
    -o-transition: all .5s ease;
}

[class~=test-box]:hover {
    transition: all .5s ease;
}

@media screen and (max-width:1050px) {
    .land-container {
        width: 85%;
    }

    [class~=land-3-banner-conte],
    [class~=land-3-banner-form] {
        width: 100%;
    }

    [class~=land-3-banner-conte],
    [class~=land-3-banner-form] {
        padding-right: 0px;
    }

    .land-3-agen-right,
    [class~=land-3-banner-form],
    [class~=land-3-agen-left],
    [class~=land-3-banner-conte] {
        padding-left: 0in;
    }

    [class~=land-3-agen-right] ul::after {
        left: .135416667in;
    }

    .land-3-banner-conte h1,
    [class~=land-3-banner-conte] p,
    [class~=land-3-banner-conte] h4 {
        text-align: center;
    }

    [class~=land-3-top-logo] {
        width: 30%;
    }

    [class~=land-3-top-contact] {
        width: 70%;
    }

    [class~=land-3-banner-form] {
        margin-top: 35px;
    }

    .land-3-agen-right,
    [class~=land-3-agen-left] {
        width: 100%;
    }

    .land-event-date {
        display: table;
    }

    .land-event-date {
        width: auto;
    }

    .land-event-date {
        margin-left: auto;
    }

    .land-event-date {
        margin-bottom: 0;
    }

    .land-event-date {
        margin-right: auto;
    }

    .land-event-date {
        margin-top: 0;
    }

    [class~=land-event-date-right] h4 {
        text-align: left;
    }
}

@media screen and (max-width:992px) {
    [class~=land-3-banner-full] {
        padding-left: 0pc;
    }

    [class~=land-3-banner-full] {
        padding-bottom: 55px;
    }

    [class~=land-3-top-contact] ul li {
        margin-left: 40px;
    }

    [class~=land-3-banner-full] {
        padding-right: 0in;
    }

    [class~=land-3-banner-full] {
        padding-top: 30pt;
    }

    [class~=land-3-top-contact] ul li {
        font-size: 9pt;
    }
}

@media screen and (max-width:767px) {

    .land-3-dwn-right,
    [class~=land-3-cus-box],
    .land-3-top-logo,
    [class~=land-3-dwn-img] {
        width: 100%;
    }

    [class~=land-3-top-logo] a img {
        margin-left: auto;
    }

    [class~=land-3-dwn-img] img {
        height: auto;
    }

    [class~=land-3-top-logo] a img {
        margin-bottom: 0;
    }

    [class~=land-3-banner-conte] h1 {
        font-size: .458333333in;
    }

    [class~=land-3-banner-conte] h1 {
        line-height: 40px;
    }

    [class~=land-3-top-logo] a img {
        margin-right: auto;
    }

    [class~=land-3-dwn-right] {
        padding-left: 0pt;
    }

    [class~=land-3-agen-left],
    [class~=land-3-dwn-right] {
        text-align: center;
    }

    [class~=land-3-dwn-right] {
        margin-top: 35px;
    }

    [class~=land-3-banner-conte] h1 {
        margin-bottom: 20px;
    }

    [class~=land-3-top-contact] {
        display: none;
    }

    [class~=land-3-top-logo] a img {
        margin-top: 0;
    }

    [class~=land-3-cus-box] {
        float: left;
    }

    [class~=land-3-cus-box] {
        padding-left: .208333333in;
    }

    [class~=land-3-cus-tit] h2,
    .land-3-dwn-right h2 {
        font-size: 36px;
    }

    [class~=land-3-cus-box],
    [class~=land-3-cus-box] {
        padding-bottom: 0in;
    }

    [class~=land-3-cus-box] {
        padding-right: .208333333in;
    }

    [class~=land-3-cus-box],
    [class~=land-3-cus-box] {
        padding-top: 0in;
    }

    [class~=land-3-cus-box] {
        margin-bottom: .729166667in;
    }

    [class~=land-3-agen-left] {
        margin-bottom: 30px;
    }

}