/*----------------------------------------- [MASTER STYLE SHEET] * Project: Landing Page HTML5 Template * Version: v1.1.1 * Copyright 2018-2020 Sends * Last Changes: 21/08/2018 * Author: RN53 Themes -----------------------------------------------*/
/*--------------------------------------------- [TABLE OF CONTENT] 1. COMMON STYLES 2. PRE LOADING 3. CONTAINER SIZE 4. TOP SECTION 5. BANNER SECTION 6. BOOKING FORM 7. PACKAGES 8. CUSTOMER TESTIMONIALS 9. FOOTER 10. TRAVEL PLAN 11. MOBILE FRIENDLY STYLES(RESPONSIVE STYLES) ------------------------------------------------*/
/*-------------------------------------------------*/
/* = COMMON STYLES 
/*-------------------------------------------------*/
body {
    font-family: 'Poppins', sans-serif;
    margin: 0;
    font-size: 14px;
    background: #fff;
    color: #1a2a38;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
    display: block;
}

audio,
canvas,
progress,
video {
    display: inline-block;
    vertical-align: baseline;
}

audio:not([controls]) {
    display: none;
    height: 0;
}

[hidden],
template {
    display: none;
}

a {
    text-decoration: none;
}

a:active,
a:hover {
    outline: 0;
}

abbr[title] {
    border-bottom: 1px dotted;
}

b,
strong {
    font-weight: bold;
}

li {
    list-style-type: none;
}

ul {
    padding: 0px;
    margin: 0px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0px;
    margin-bottom: 0px;
}

a {
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

a:hover {
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

div {
    box-sizing: border-box;
}

:before {
    font-family: "Material Icons";
    position: absolute;
}

:after {
    font-family: "Material Icons";
    position: absolute;
}

/* fallback */
@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url(icon.woff2) format('woff2');
}

input,
textarea,
select {
    outline: none;
    font-family: 'Poppins', sans-serif;
}

/* width */
::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

/* Placeholder text color */
::placeholder {
    color: #1a2a38;
    opacity: 1;
    /* Firefox */
}

:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #1a2a38;
}

::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #1a2a38;
}

/*-------------------------------------------------*/
/* PRE LOADING 
/*-------------------------------------------------*/
#preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    /* change if the mask should have another color then white */
    z-index: 99999;
    /* makes sure it stays on top */
}

#status {
    position: absolute;
    left: 46%;
    /* centers the loading animation horizontally one the screen */
    top: 40%;
    border: 5px solid #f3f3f3;
    border-radius: 50%;
    border-top: 5px solid #76cef1;
    width: 100px;
    height: 100px;
    -webkit-animation: spin 2s linear infinite;
    /* Safari */
    animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/*-------------------------------------------------*/
/* = CONTAINER SIZE 
/*-------------------------------------------------*/
.land-full {
    width: 100%;
    position: relative;
    display: inline-block;
}

.land-container {
    width: 1000px;
    margin: 0 auto;
    position: relative;
}

.la-container-75 {
    width: 950px;
}

.col1 {
    width: 10%;
    float: left;
    padding: 15px;
}

.col15 {
    width: 15%;
    float: left;
    padding: 15px;
}

.col2 {
    width: 20%;
    float: left;
    padding: 15px;
}

.col25 {
    width: 25%;
    float: left;
    padding: 15px;
}

.col3 {
    width: 30%;
    float: left;
    padding: 15px;
}

.col35 {
    width: 35%;
    float: left;
    padding: 15px;
}

.col4 {
    width: 40%;
    float: left;
    padding: 15px;
}

.col45 {
    width: 45%;
    float: left;
    padding: 15px;
}

.col5 {
    width: 50%;
    float: left;
    padding: 15px;
}

.col55 {
    width: 55%;
    float: left;
    padding: 15px;
}

.col6 {
    width: 60%;
    float: left;
    padding: 15px;
}

.col65 {
    width: 65%;
    float: left;
    padding: 15px;
}

.col7 {
    width: 70%;
    float: left;
    padding: 15px;
}

.col75 {
    width: 75%;
    float: left;
    padding: 15px;
}

.col8 {
    width: 80%;
    float: left;
    padding: 15px;
}

.col85 {
    width: 85%;
    float: left;
    padding: 15px;
}

.col9 {
    width: 90%;
    float: left;
    padding: 15px;
}

.col95 {
    width: 95%;
    float: left;
    padding: 15px;
}

/*-------------------------------------------------*/
/* = TOP SECTION 
/*-------------------------------------------------*/
.header {
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    position: absolute;
    top: 0px;
    padding: 20px 0px 5px 0px;
}

.header1 {
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    top: -100px;
}

.header2 {
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    top: 0px;
    z-index: 9;
    padding-top: 16px;
    position: fixed;
    /* fallback for old browsers */
    /* Chrome 10-25, Safari 5.1-6 */
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    background: #1357b7;
    background: -webkit-linear-gradient(to top, #054bb3, #1357b7);
    background: linear-gradient(to top, #054bb3, #1357b7);
    box-shadow: 0px 5px 29px 4px rgba(56, 56, 56, 0.64);
}

.land-3 {}

.land-3-top-full {
    background: #ffffff;
    position: fixed;
    display: inline-block;
    width: 100%;
    height: 55px;
    padding: 10px 0px;
    top: 0px;
    z-index: 9;
    box-shadow: 0 3px 35px 0.6px rgba(40, 30, 20, 0.29);
    /* background: linear-gradient(to top, #262e3e, #999ca3);
     */
}

.land-3-top {
    position: relative;
    width: 100%;
    display: inline-block;
    /* margin-bottom: 25px;
     */
    z-index: 2;
}

.land-3-top-logo {
    float: left;
    width: 40%;
}

.land-3-top-logo a {}

.land-3-top-logo a img {
    margin-top: 2px;
    width: 170px;
    height: 32px;
    -webkit-object-fit: cover;
    -moz-object-fit: cover;
    -o-object-fit: cover;
    -ms-object-fit: cover;
    object-fit: cover;
}

.land-3-top-contact {
    float: left;
    width: 60%;
}

.land-3-top-contact ul {
    float: right;
    padding-top: 7px;
}

.land-3-top-contact ul li {
    color: #ffffff;
    float: left;
    margin-left: 50px;
    font-weight: 500;
}

.land-3-top-contact ul li a {
    color: #ffffff;
    cursor: pointer;
}

.land-3-top-contact ul li:last-child a {
    background: #ed2121;
    color: #fff;
    padding: 10px 30px 10px 40px;
    border-radius: 2px;
}

.land-3-top-contact ul li a:before {
    content: "person";
    font-size: 18px;
    margin-top: 0px;
    margin-left: -24px;
    color: #ffffff;
    font-weight: 500;
}

.land-3-top-contact ul li:nth-child(1) a:before {
    content: "home";
}

.land-3-top-contact ul li:nth-child(2) a:before {
    content: "dvr";
}

.land-3-top-contact ul li:nth-child(3) a:before {
    content: "assistant_photo";
}

.land-3-top-contact ul li:nth-child(4) a:before {
    content: "file_download";
    color: #fff;
}

.land-3-banner-full {
    position: relative;
    /* background: url(../images/grass.png) no-repeat center bottom;
     */
    background-size: 100%;
    padding: 100px 0px 0px 0px;
    /* margin-top: 55px;
     */
    overflow: hidden;
}

.land-3-banner-full:before {
    content: '';
    position: absolute;
    width: 158%;
    height: 185%;
    background: #8200ae;
    background: -webkit-linear-gradient(to right, #37a6e1, #8200ae);
    background: linear-gradient(to right, #37a6e1, #8200ae);
    top: -100%;
    left: -22%;
    -webkit-transform: rotate(-5deg);
    -moz-transform: rotate(-5deg);
    -o-transform: rotate(-5deg);
    -ms-transform: rotate(-5deg);
    transform: rotate(-5deg);
}

.land-3-banner-full:after {
    content: '';
    position: absolute;
    /* background: url(/assets/images/product-bg.png) no-repeat; */
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background-size: cover;
    opacity: 0.1;
}

@keyframes animatedBackground {
    from {
        background-position: 0 0;
    }

    to {
        background-position: 100% 0;
    }
}

/*-------------------------------------------------*/
/* = BANNER SECTION 
/*-------------------------------------------------*/
.land-3-banner {
    z-index: 5;
    position: relative;
}

.land-3-banner-conte {
    float: left;
    width: 60%;
    color: #ffffff;
    padding: 40px 60px 50px 0px;
}

.land-3-banner-conte h1 {
    font-size: 60px;
    line-height: 60px;
    padding-bottom: 14px;
    color: #fff;
}

.land-3-banner-conte h1 span {
    /* font-size: 45px;
     */
    /* line-height: 45px;
     */
    /* padding-bottom: 6px;
     */
    font-weight: 100;
    display: block;
    color: #e1e5e9;
}

.tit1 {
    font-size: 32px;
    line-height: 32px;
}

.tit2 {
    font-size: 24px;
    line-height: 24px;
    margin-top: 7px;
}

.land-3-banner-conte h4 {
    font-size: 20px;
    font-weight: 300;
}

.land-3-banner-conte p {
    color: #f3f3f3;
    /* border-bottom: 1px solid #737fc5;
     */
    padding-bottom: 0px;
    margin-bottom: 30px;
    font-weight: 300;
}

.land-3-banner-conte a {
    border: 1px solid #ffffff;
    border-radius: 50px;
    padding: 5px 30px;
    color: #fff;
    font-size: 16px;
}

.demo-form {
    width: 75%;
    background: #fcfdff;
    position: relative;
    display: inline-block;
    padding: 15px;
    border-radius: 3px;
    box-shadow: 0px 3px 8px -2px rgba(21, 29, 37, 0.31);
}

.demo-form ul {}

.demo-form li {
    float: left;
    width: 40%;
}

.demo-form li input {
    width: 100%;
    height: 42px;
    background: #f3f3f5;
    border: 0px solid #123452;
    padding: 5px 15px;
    box-sizing: border-box;
    color: #000;
}

.demo-form li:nth-child(1) {
    width: 60%;
}

.demo-form li:nth-child(2) input[type="submit"] {
    font-weight: 600;
    font-size: 16px;
    color: #fff;
    background: #17b365;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to bottom, #25ce7a, #17b365);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to bottom, #25ce7a, #17b365);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    text-shadow: 0px 0px 0px #414950;
    /* z-index: 99;
     */
    cursor: pointer;
    text-transform: uppercase;
    letter-spacing: 0.5px;
}

/*-------------------------------------------------*/
/* = BOOKING FORM 
/*-------------------------------------------------*/
.land-3-banner-form {
    float: left;
    width: 40%;
    padding-left: 20px;
}

.land-com-form {
    padding: 30px 40px 30px 40px;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0 16px 37px -22px rgba(2, 2, 2, 0.32);
    box-shadow: 0 3px 14px -1px rgba(0, 0, 0, 0.2);
    width: 400px;
    margin: 0 auto;
    margin-top: 40px;
}

.land-com-form h2 {
    font-size: 22px;
    text-align: center;
    margin-bottom: 18px;
    color: #273c56;
    /* background: #273c56;
     */
    /* padding: 10px;
     */
    /* border-radius: 10px 10px 0px 0px;
     */
}

.land-com-form form {
    /* padding: 20px 50px 25px 50px;
     */
    width: 100%;
    box-sizing: border-box;
}

.land-com-form form ul {}

.land-com-form form ul li {
    margin-bottom: 15px;
    position: relative;
    list-style-type: none;
}

.land-com-form form ul li:before {
    font-size: 18px;
    margin-top: 14px;
    margin-left: 12px;
    color: #6a717f;
    font-weight: 500;
}

.land-com-form form ul li input,
select {
    width: 100%;
    display: block;
    line-height: 29px;
    font-size: 14px;
    outline: none;
    height: 45px;
    background: #ffffff;
    padding-left: 15px;
    box-sizing: border-box;
    border-radius: 3px;
    color: #1a2a38;
    border: 1px solid #e4e4e4;
    background: #f5f5f5;
}

.form-control {
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.text-center {
    text-align: center!important;
}
.error {
    color: #d32f2f!important;
    text-align: left!important;
    margin-top: 3px;
    margin-bottom: 5px;
    font-size: 0.75rem!important;
}
.land-com-form form ul li input[id="btnSubmit"] {
    background: #ffb100;
    color: #fff;
    height: 50px;
    line-height: 50px;
    font-weight: 600;
    font-size: 16px;
    text-align: center;
    border-radius: 3px;
    cursor: pointer;
    padding-left: 0;
    text-transform: capitalize;
}

.land-com-form form ul li:nth-child(5):before {}

.land-com-form form ul li:nth-child(5):hover:before {}

.land-com-form form ul li p {
    margin-bottom: 0px;
    color: #6a6a6a;
    font-size: 12px;
    text-align: center;
}

.land-com-form form ul li p a {
    color: #ffb100;
}

.land-com-form form ul li:last-child {
    margin-bottom: 0px;
}

input:focus~.floating-label,
input:not(:focus):valid~.floating-label {
    left: 48px;
    top: 0px;
    font-size: 11px;
    opacity: 0;
    font-size: 8px;
}

.inputText {
    font-size: 14px;
    width: 200px;
    height: 35px;
}

.floating-label {
    position: absolute;
    pointer-events: none;
    left: 48px;
    top: 11px;
    transition: 0.2s ease all;
}

.land-3-dwn-full {
    /* position: relative;
     */
    display: inline-block;
    padding: 60px 0px;
}

.land-3-dwn {}

.land-3-dwn-img {
    float: left;
    width: 40%;
}

.land-3-dwn-img img {
    width: 100%;
    height: 350px;
    -webkit-object-fit: cover;
    -moz-object-fit: cover;
    -o-object-fit: cover;
    -ms-object-fit: cover;
    object-fit: cover;
}

.land-3-dwn-right {
    float: left;
    width: 60%;
    padding-left: 100px;
}

.land-3-dwn-right h2 {
    font-size: 48px;
    line-height: 62px;
}

.land-3-dwn-right p {
    line-height: 26px;
    color: #495e71;
}

.land-3-dwn-right a {
    margin-right: 15px;
}

.land-3-banner-form {}

.land-3-banner-form img {
    width: 77%;
    margin-top: 28px;
    box-shadow: 0px 19px 15px -15px rgba(0, 0, 0, 0.76);
    border-radius: 30px;
}

.ava-tit {}

.ava-tit h2 {
    text-align: center;
    margin-bottom: 35px;
    font-size: 30px;
    font-weight: 300;
    line-height: 30px;
}

.ava-tit h2 span {
    font-size: 60px;
    line-height: 60px;
    font-weight: 700;
    display: block;
}

.enquiry {
    position: relative;
    display: inline-block;
    width: 100%;
    padding-top: 80px;
}

.enquiry-left {
    float: left;
    width: 20%;
    color: #ffffff;
    padding: 0px 60px 0px 0px;
}

.enquiry-left img {
    width: 100%;
}

.enquiry-left-1 {
    float: left;
    width: 40%;
    color: #1c2938;
    padding: 0px 60px 50px 0px;
}

.enquiry-left-1 ul {}

.enquiry-left-1 ul li {
    margin-bottom: 25px;
    padding-left: 50px;
}

.enquiry-left-1 ul li h4 {
    font-size: 18px;
}

.enquiry-left-1 ul li p {
    font-size: 13px;
}

.enquiry-left-1 ul li:before {
    font-size: 40px;
    margin-top: 4px;
    margin-left: -58px;
    color: #17b365;
    font-weight: 500;
}

.enquiry-left-1 ul li:nth-child(1):before {
    content: "file_download";
}

.enquiry-left-1 ul li:nth-child(2):before {
    content: "adb";
}

.enquiry-left-1 ul li:nth-child(3):before {
    content: "headset";
}

.enquiry-right {
    /* float: left;
     */
    /* width: 40%;
     */
}

.mov-cloud {
    z-index: 0;
    opacity: 0.2;
}

@-webkit-keyframes animateCloud {
    0% {
        margin-left: -1000px;
    }

    100% {
        margin-left: 100%;
    }
}

@-moz-keyframes animateCloud {
    0% {
        margin-left: -1000px;
    }

    100% {
        margin-left: 100%;
    }
}

@keyframes animateCloud {
    0% {
        margin-left: -1000px;
    }

    100% {
        margin-left: 100%;
    }
}

/* ANIMATIONS */
.x1 {
    -webkit-animation: animateCloud 35s linear infinite;
    -moz-animation: animateCloud 35s linear infinite;
    animation: animateCloud 35s linear infinite;
    -webkit-transform: scale(0.65);
    -moz-transform: scale(0.65);
    transform: scale(0.25);
}

.x2 {
    -webkit-animation: animateCloud 20s linear infinite;
    -moz-animation: animateCloud 20s linear infinite;
    animation: animateCloud 20s linear infinite;
    -webkit-transform: scale(0.3);
    -moz-transform: scale(0.3);
    transform: scale(0.3);
}

.x3 {
    -webkit-animation: animateCloud 30s linear infinite;
    -moz-animation: animateCloud 30s linear infinite;
    animation: animateCloud 30s linear infinite;
    -webkit-transform: scale(0.5);
    -moz-transform: scale(0.5);
    transform: scale(0.5);
}

.x4 {
    -webkit-animation: animateCloud 18s linear infinite;
    -moz-animation: animateCloud 18s linear infinite;
    animation: animateCloud 18s linear infinite;
    -webkit-transform: scale(0.4);
    -moz-transform: scale(0.4);
    transform: scale(0.4);
}

.x5 {
    -webkit-animation: animateCloud 25s linear infinite;
    -moz-animation: animateCloud 25s linear infinite;
    animation: animateCloud 25s linear infinite;
    -webkit-transform: scale(0.55);
    -moz-transform: scale(0.55);
    transform: scale(0.55);
}

/* OBJECTS */
.cloud {
    background: #fff;
    background: -moz-linear-gradient(top, #fff 5%, #f1f1f1 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(5%, #fff), color-stop(100%, #f1f1f1));
    background: -webkit-linear-gradient(top, #8ab9e6 5%, #9cceff 100%);
    background: -o-linear-gradient(top, #fff 5%, #f1f1f1 100%);
    background: -ms-linear-gradient(top, #fff 5%, #f1f1f1 100%);
    background: linear-gradient(top, #fff 5%, #f1f1f1 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff', endColorstr='#f1f1f1', GradientType=0);
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    border-radius: 100px;
    -webkit-box-shadow: 0 8px 5px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 8px 5px rgba(0, 0, 0, 0.1);
    box-shadow: 0 8px 5px rgba(0, 0, 0, 0.1);
    height: 120px;
    position: relative;
    width: 350px;
    opacity: 0.3;
}

.cloud:after,
.cloud:before {
    background: #8ab9e6;
    content: '';
    position: absolute;
    z-indeX: -1;
}

.cloud:after {
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    border-radius: 100px;
    height: 100px;
    left: 50px;
    top: -50px;
    width: 100px;
}

.cloud:before {
    -webkit-border-radius: 200px;
    -moz-border-radius: 200px;
    border-radius: 200px;
    width: 180px;
    height: 180px;
    right: 50px;
    top: -90px;
}

/*-------------------------------------------------*/
/* = TRANSION EFFECTS 
/*-------------------------------------------------*/
@-webkit-keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@-moz-keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.fade-in {
    opacity: 0;
    /* make things invisible upon start */
    -webkit-animation: fadeIn ease-in 1;
    /* call our keyframe named fadeIn, use animattion ease-in and repeat it only 1 time */
    -moz-animation: fadeIn ease-in 1;
    animation: fadeIn ease-in 1;
    -webkit-animation-fill-mode: forwards;
    /* this makes sure that after animation is done we remain at the last keyframe value (opacity: 1)*/
    -moz-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-duration: 1s;
    -moz-animation-duration: 1s;
    animation-duration: 1s;
}

.fade-in.one {
    -webkit-animation-delay: 0.5s;
    -moz-animation-delay: 0.5s;
    animation-delay: 0.5s;
}

.fade-in.two {
    -webkit-animation-delay: 0.5s;
    -moz-animation-delay: 0.5s;
    animation-delay: 0.5s;
}

.fade-in.three {
    -webkit-animation-delay: 1.6s;
    -moz-animation-delay: 1.6s;
    animation-delay: 1.6s;
}

.fade-in.four {
    -webkit-animation-delay: 0.5s;
    -moz-animation-delay: 0.5s;
    animation-delay: 0.5s;
}

/*-------------------------------------------------*/
/* = FOOTER 
/*-------------------------------------------------*/
.land-1-foot-full {
    background: hsl(0, 0%, 100%);
    padding: 10px 0px;
}

.land-1-foot-text {}

.land-1-foot-text p {
    text-align: center;
    margin: 0px;
    font-size: 13px;
    color: #908f8f;
}

.land-1-foot-text p a {
    color: #908f8f;
}

/*-------------------------------------------------*/
/* = PRODUCT SHOWCASE 
/*-------------------------------------------------*/
.products-main {
    border-top: 1px solid #eaeaea;
    padding-top: 70px;
}

.all-products {
    padding: 70px 0px;
}

.ed-res-bg {
    /* background: url(/assets/images/mac.png) no-repeat center center #f1f1f1; */
}

.ed-rese-grid {
    box-sizing: border-box;
    background: #fff;
    border: 1px solid #ececec;
    position: relative;
    /* display: inline-block;
     */
    overflow: hidden;
    margin: 0px 8px;
    box-shadow: 0px 7px 12px -9px rgba(14, 14, 14, 0.8);
}

.ed-rsear {}

.ed-rese-grid {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

.ed-rese-grid:hover {
    -webkit-transform: scale(1.02);
    transform: scale(1.02);
}

.ed-rsear-in {
    margin: 0 auto;
    display: table;
    position: relative;
    overflow: hidden;
    width: 80%;
}

.ed-rsear-in ul {}

.ed-rsear-in ul li {
    float: left;
    width: 50%;
    box-sizing: border-box;
    padding: 10px;
}

.ed-rsear-img {
    float: left;
}

.ed-rsear-img img {
    width: 40px;
    height: 40px;
    object-fit: cover;
    padding: 20px 0px 0px 20px;
}

.ed-rsear-dec {
    float: left;
    padding: 20px;
}

.ed-rsear-dec h4 {}

.ed-rsear-dec h4 a {
    font-size: 14px;
    color: #273c56;
    font-weight: 700;
}

.ed-rsear-dec a {
    font-size: 13px;
    font-weight: 500;
    color: #273c56;
    margin-right: 15px;
}

.ed-rsear-dec a span {
    font-size: 13px;
    color: #33a923;
    font-weight: 500;
}

.ed-flag {
    position: absolute;
    right: 20px;
    top: 20px;
}

.ed-flag img {
    border-radius: 10px;
}

.ed-flag a {}

.ed-faci-full-top {}

.ed-faci-full-top img {
    width: 100%;
}

.ed-faci-full-bot {}

.ed-faci-full-bot h4 {
    margin-bottom: 15px;
}

.ed-faci-full-bot h4 a {}

.ed-faci-full-bot p {
    font-size: 12px;
}

.read-line-btn {
    padding: 6px 25px;
    border-radius: 3px;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;
    height: 35px;
    text-align: center;
    /* margin: 0px 10px;
     */
    /* background: linear-gradient(to top, #ef612f, #f36b3b);
     */
    color: #02294e !important;
    border: 1px solid #02294e;
    margin-top: 10px;
    display: inline-block;
}

.ed-rese-grid-mar-bot-30 {
    margin-bottom: 30px;
}

.s17-eve-time {
    width: 100%;
    border-bottom: 1px solid #ececec;
    padding: 10px 10px 25px 10px;
    margin-bottom: 25px;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
}

.s17-eve-time-tim {
    float: left;
    width: 25%;
    box-sizing: border-box;
}

.s17-eve-time-msg {
    float: left;
    width: 75%;
    padding-right: 80px;
    box-sizing: border-box;
}

.alert {
    position: relative;
    padding: .75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: .25rem;
}

.alert-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
    text-align: center;
}

.foot-blue {
    background: #eaf8ff;
    border-top: 1px solid #c1d7e3;
    padding: 60px 0px;
    border-bottom: 1px solid #c1d7e3;
}

.foot-sec {
    position: relative;
    overflow: hidden;
    margin-top: 55px;
}

.foot-sec-com {
    float: left;
    width: 33.333%;
    padding: 0px 15px;
}

.foot-sec-com h4 {
    margin: 15px 0px;
    font-size: 16px;
    line-height: 25px;
}

.foot-sec-com img {
    width: 90px;
}

.foot-sec-com p {
    margin: 0px;
    font-size: 13px;
}

.vid-full {
    padding-bottom: 70px;
}

.vid-sec {
    position: relative;
    overflow: hidden;
    /* border-top: 1px solid #e1e5e7; */
    padding-top: 70px;
}

.vid-sec-lhs {
    float: left;
    width: 50%;
    padding-right: 50px;
}

.vid-sec-lhs h2 {
    font-size: 38px;
    line-height: 44px;
    margin-bottom: 25px;
}

.vid-sec-lhs h4 {
    font-size: 18px;
    margin-top: 25px;
    margin-bottom: 10px;
}

.vid-sec-lhs p {
    margin: 0px;
}

.vid-sec-lhs a {
    color: #ffffff;
    font-size: 13px;
    padding: 5px 20px;
    display: inline-block;
    background: #327fe1;
    border-radius: 2px;
    margin-top: 25px;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.5px;
}

.vid-sec-rhs {
    float: left;
    width: 50%;
}

.vid-sec-rhs iframe {
    width: 100%;
    height: 281px;
}

.country {
    padding: 70px 0px;
    /* background: url(/assets/images/worldmap.png) no-repeat center #1156c2; */
    background-size: 50%;
}

.country-inn {
    text-align: center;
    font-size: 24px;
    font-weight: 300;
    color: #fff;
}

.country-inn h4 {
    font-weight: 200;
}

.country-inn h4 span {}

.country .cont1 {
    font-weight: 700;
    font-size: 32px;
}

.country .cont2 {
    font-weight: 700;
    font-size: 32px;
    display: block;
}

.price {
    padding: 70px 0px;
}

.price-inn {}

.pri-table {
    margin-top: 45px;
}

.pri-table table,
th,
td {
    border-collapse: collapse;
    border: 1px solid #ebecef;
    padding: 15px;
}

.pri-table table {
    width: 100%;
}

.pri-table table td img {
    margin: 0 auto;
    display: table;
}

.pri-table table tr th {}

.pri-table table tr th:nth-child(1) {}

.pri-table table tr td {
    width: 30%;
}

.pri-table table tr td:nth-child(1) {
    width: 40%;
    padding-left: 50px;
}

.pri-buy {
    float: right;
    position: relative;
    overflow: hidden;
    padding: 40px 0px 0px 0px;
}

.btn-pri-buy {
    background: #ed2121;
    color: #fff;
    padding: 10px 30px 10px 40px;
    border-radius: 2px;
    display: inline-block;
}

.btn-pri-get {
    background: #949494;
    color: #fff;
    padding: 10px 30px;
    border-radius: 2px;
}

/*-------------------------------------------------*/
/* = MOBILE FRIENDLY STYLES(RESPONSIVE STYLES) 
/*-------------------------------------------------*/
/*-------------------------------------------------*/
/* = When the browser is between 0px and 1050px wide,below css will be applied. 
/*-------------------------------------------------*/
@media screen and (max-width:1050px) {
    .land-container {
        width: 85%;
    }

    .land-3-banner-conte {
        width: 60%;
    }

    .land-3-banner-form {
        width: 40%;
    }

    .land-3-banner-form img {
        width: 96%;
        margin-top: 0px;
        position: relative;
    }

    .land-3-top-logo {
        width: 30%;
    }

    .land-3-top-contact {
        width: 70%;
    }

    .land-3-banner-form {
        margin-top: 35px;
    }

    .land-3-banner-full:before {}
}

/*-------------------------------------------------*/
/* = When the browser is between 0px and 992px wide,below css will be applied. 
/*-------------------------------------------------*/
@media screen and (max-width:992px) {
    .land-3-top-contact ul li {
        margin-left: 40px;
        font-size: 12px;
    }

    .land-3-banner-full {
        padding: 40px 0px 55px 0px;
    }

    .land-pack {
        width: 100%;
    }

    .enquiry-left {
        display: none;
    }

    .enquiry-left-1 {
        width: 50%;
        padding: 0px 60px 50px 0px;
    }

    .enquiry-right {
        width: 100%;
    }

    .ed-rsear-in {
        width: 100%;
    }
}

/*-------------------------------------------------*/
/* = When the browser is between 0px and 767px wide,below css will be applied. 
/*-------------------------------------------------*/
@media screen and (max-width:767px) {

    .land-3-top-logo,
    .land-3-dwn-img,
    .land-3-dwn-right {
        width: 100%;
    }

    .land-3-top-contact {
        display: none;
    }

    .land-3-top-logo a img {
        margin: 0 auto;
        display: table;
    }

    .land-3-banner-conte h1 {
        font-size: 44px;
        line-height: 57px;
        margin-bottom: 20px;
        text-align: center;
    }

    .land-3-banner-conte {
        width: 100%;
        padding: 50px 0px 50px 0px;
    }

    .land-3-banner-form {
        width: 100%;
    }

    .land-3-banner-form img {
        width: 75%;
        margin: 0 auto;
        display: table;
    }

    .land-3-banner-conte p {
        text-align: center;
    }

    .demo-form {
        width: 75%;
        display: table;
        margin: 0 auto;
    }

    .ed-rsear-in ul li {
        width: 100%;
        padding: 10px 0px;
    }

    .ed-flag img {
        border-radius: 3px;
        width: 20px;
    }

    .ava-tit h2 {
        font-size: 24px;
        line-height: 24px;
    }

    .ava-tit h2 span {
        font-size: 42px;
        line-height: 42px;
    }

    .enquiry-left-1 {
        width: 100%;
        padding: 0px 0px 50px 0px;
    }

    .enquiry-right {
        width: 100%;
    }

    .foot-sec-com {
        width: 100%;
        padding: 25px 0px;
        border-bottom: 1px solid #dee8eb;
        text-align: center;
    }

    .vid-sec-lhs {
        float: left;
        width: 100%;
        padding-right: 0px;
        text-align: center;
        padding-bottom: 50px;
    }

    .vid-sec-rhs {
        width: 100%;
    }

    .land-3-banner-conte a {
        margin: 0 auto;
        display: table;
    }
}

/*-------------------------------------------------*/
/* = When the browser is between 0px and 480px wide,below css will be applied. 
/*-------------------------------------------------*/
@media screen and (max-width:480px) {
    .land-3-dwn-right a {
        margin-right: 0px;
        width: 100%;
    }

    .land-3-banner-conte h1 span {
        font-size: 26px;
        line-height: 28px;
    }

    .land-3-banner-conte h1 {
        font-size: 32px;
    }

    .pri-table table tr td:nth-child(1) {
        padding-left: 20px;
    }

    .pri-table table,
    th,
    td {
        padding: 15px 8px;
    }
}

/*-------------------------------------------------*/
/* = When the browser is between 0px and 400px wide,below css will be applied. 
/*-------------------------------------------------*/
@media screen and (max-width:400px) {}